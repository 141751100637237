<template>
  <div class="tfoms__conditions">
    <div class="tfoms__conditions-header">Условия оказания МП</div>

    <v-radio-group v-model="selectedItem">
      <v-list>
        <v-list-item-group v-model="selectedItem" color="primary">
          <v-list-item
            v-for="item in HealthCares"
            :key="item.Id"
            :value="item.Id"
          >
            <v-list-item-icon>
              <v-radio
                readonly
                disabled
                :value="item.Id"
                @click="selectedItem = item.Id"
              ></v-radio>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.Name"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-radio-group>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Number,
      default: 1,
    },
    HealthCares: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      items: [
        "Амбулаторно",
        "Стационарно",
        "В дневном стационаре",
        "Вне медицинской организации",
      ],
      selectedItem: this.value,
      radios: null,
    };
  },
  watch: {
    selectedItem(val) {
      this.$emit("input", val);
    },
  },
};
</script>
<style lang="scss">
.tfoms__conditions {
  width: 389px;
  height: 709px;
  background-color: white;
  border-radius: 10px;

  &-header {
    height: 78px;
    background-color: #e8ecff;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .v-input--radio-group {
    padding: 0 20px 20px 20px;
  }
  .v-list-item:not(:last-child) {
    border-bottom: solid 1px #8a90a4;
  }
}
</style>
